<template>
  <!-- 時間軸 開始-->
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-5 pb-lg-4 d-block position-relative"
    style="z-index: 10; background-color: transparent"
  >
    <div class="container">
      <div
        class="d-flex flex-row justify-content-center pb-2 section-title mb-2 mb-lg-4"
        ref="target1"
        :class="{
          'animate__animated animate__zoomIn': targetIsVisible1,
          'opacity-0': !targetIsVisible1,
        }"
      >
        <h2 class="fw-bold text-center text-white bg-feature rounded p-4 lh-lg">
          《世紀末的對話》<br> 臺灣原住民族的帝國初體驗
        </h2>
      </div>
      <div class="row g-2 g-lg-4">
        <div class="container pt-4 pt-lg-5">
          <div class="main-timeline-2">
            <div class="timeline-2 left-2">
              <div class="row">
                <div class="col-lg-8 d-flex align-self-center">
                  <div
                    class="border border-4 border-white w-100 loading"
                    style="height: 408px"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible1,
                      'opacity-0': !targetIsVisible1,
                    }"
                  >
                    <iframe
                      width="100%"
                      height="400"
                      src="https://www.youtube.com/embed/oj_Lm4qq7Ow?si=HyXRYyXUWbcnimVv"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div
                  class="col-lg-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    class="bg-feature rounded p-4"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible1,
                      'opacity-0': !targetIsVisible1,
                    }"
                  >
                    <h2
                      class="fs-4 text-white text-center fw-bold pt-2 pb-2 d-block"
                    >
                      <i class="fa-solid fa-location-dot me-2 mt-2"></i>
                      <span class="text-shadow font-1-1em"
                        >第一集：各懷心思的啟航</span
                      >
                    </h2>
                    <div class="d-block text-left">
                      <p class="card-text text-white pt-2 pb-2 text-shadow font-1-2em">
                        西元1895年，中日甲午戰爭後，臺灣被迫割讓給日本。這不但開啟了臺灣史新的一頁，也讓世居此地的原住民族群，再次面對全新的外來政權。在《蕃人觀光日誌》裡，除了彼此互動與應對的言行紀錄外，其實還可以感受到一種未被具體呈現，卻存在於原住民內心的疑問：我們跟他們將會是什麼樣的關係呢？ 
                      </p>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
            <div class="timeline-2 right-2">
              <div class="row">
                <div class="col-lg-8 order-lg-2 d-flex align-self-center">
                  <div
                    class="border border-4 border-white w-100 loading"
                    style="height: 408px"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible2,
                      'opacity-0': !targetIsVisible2,
                    }"
                  >
                    <iframe
                      width="100%"
                      height="400"
                      src="https://www.youtube.com/embed/b9VyvRVIizU?si=wsMKCpHVG_R_svzp"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div
                  class="col-lg-4 order-lg-1 d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    class="bg-feature rounded p-4"
                    ref="target2"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible2,
                      'opacity-0': !targetIsVisible2,
                    }"
                  >
                    <h2
                      class="fs-4 text-white text-center fw-bold pt-2 pb-2 d-block"
                    >
                      <i class="fa-solid fa-location-dot me-2 mt-2"></i
                      ><span class="text-shadow font-1-1em"
                        >第二集：驚異衝擊的旅程</span
                      >
                    </h2>
                    <div class="d-block text-left">
                      <p class="card-text text-white pt-2 pb-2 text-shadow font-1-2em">
                        觀光團一行人自基隆港登上釜山丸，經過4天3夜的海上航行後，8月6日清晨，抵達日本長崎港，展開進入帝國的觀光旅程。此後，一路前行，經過門司、宇品、神戶、名古屋，最後到達目的地東京。對於第一次離開山林部落，跨越大海來到日本的臺灣原住民來說，他們在此體驗了生命中的諸多第一次，種種好奇、驚嘆、觀察或省思，引發了不少趣事。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="timeline-2 left-2">
              <div class="row">
                <div class="col-lg-8 d-flex align-self-center">
                  <div
                    class="border border-4 border-white w-100 loading"
                    style="height: 408px"
                    ref="target3"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible3,
                      'opacity-0': !targetIsVisible3,
                    }"
                  >
                    <iframe
                      width="100%"
                      height="400"
                      src="https://www.youtube.com/embed/JJ2Mr62RfUg?si=Y1UasnPoj95IKkTC"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div
                  class="col-lg-4 d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    class="bg-feature rounded p-4"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible3,
                      'opacity-0': !targetIsVisible3,
                    }"
                  >
                    <h2
                      class="fs-4 text-white text-center fw-bold pt-2 pb-2 d-block"
                    >
                      <span class="text-shadow font-1-1em"
                        ><i class="fa-solid fa-location-dot me-2 mt-2"></i
                        >第三集：鏡裡鏡外的東京</span
                      >
                    </h2>
                    <div class="d-block text-left">
                      <p class="card-text text-white pt-2 pb-2 text-shadow font-1-2em">
                        8月11日一早，原住民族觀光團從名古屋出發，前往此行最終目的：東京。沿途話題不時圍繞在昨天看到的農耕事務上，更希望能有機會帶些種子回部落試種。大夥邊聊邊睡，入夜時分終於來到東京，隨後安排住進新橋附近的旅店。隔天，為舒緩長途旅行的疲累，並未安排活動。13日，在長野主事、緒方通事帶領下，一早來到東京帝國飯店會客室拜見因公務先行回東京的臺灣總督乃木希典將軍。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="timeline-2 right-2">
              <div class="row">
                <div class="col-lg-8 order-lg-2 d-flex align-self-center">
                  <div
                    class="border border-4 border-white w-100 loading"
                    style="height: 408px"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible4,
                      'opacity-0': !targetIsVisible4,
                    }"
                  >
                    <iframe
                      width="100%"
                      height="400"
                      src="https://www.youtube.com/embed/8oby1t4x_Ek?si=6bgAveO4eASb6vSJ"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
                <div
                  class="col-lg-4 order-lg-1 d-flex flex-column justify-content-center align-items-center"
                >
                  <div
                    class="bg-feature rounded p-4"
                    ref="target4"
                    :class="{
                      'animate__animated animate__zoomIn': targetIsVisible4,
                      'opacity-0': !targetIsVisible4,
                    }"
                  >
                    <h2
                      class="fs-4 text-white text-center fw-bold pt-2 pb-2 d-block"
                    >
                      <i class="fa-solid fa-location-dot me-2 mt-2"></i
                      ><span class="text-shadow font-1-1em"
                        >第四集：湧動矛盾的參訪</span
                      >
                    </h2>
                    <div class="d-block text-left">
                      <p class="card-text text-white pt-2 pb-2 text-shadow font-1-2em">
                        在參觀了上野公園，並順道遊歷淺草之後，今天緒方通事再帶大家前往靖國神社，並參觀遊就館。正當每個人看著閃爍的刀劍、刀鋒而各有所思時，緒方通事通知大家集合，準備回旅店，回程經過神社前表參道，中途點豎立著
                        大村益次郎兵部大輔的銅像。緒方通事特別停下來為大家介紹
                        大村益次郎殉難報國的事蹟，表彰他能為天皇犧牲效忠的精神。當大家聽得入神時，宇旺腦中浮起了一個意念。
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="timeline-2 left-2">
            <div class="row">
              <div class="col-lg-8 d-flex align-self-center">
                <div
                  class="border border-4 border-white w-100 loading"
                  style="height: 408px"
                  ref="target5"
                  :class="{
                    'animate__animated animate__zoomIn': targetIsVisible5,
                    'opacity-0': !targetIsVisible5,
                  }"
                >
                  <iframe
                    width="100%"
                    height="400"
                    src="https://www.youtube.com/embed/oh0qGAzl2sI?si=UgfhMrimHEyAFBY7"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </div>
              </div>
              <div
                class="col-lg-4 d-flex flex-column justify-content-center align-items-center"
              >
                <div
                  class="bg-feature rounded p-4"
                  :class="{
                    'animate__animated animate__zoomIn': targetIsVisible5,
                    'opacity-0': !targetIsVisible5,
                  }"
                >
                  <h2
                    class="fs-4 text-white text-center fw-bold pt-2 pb-2 d-block"
                  >
                    <span class="text-shadow font-1-1em"
                      ><i class="fa-solid fa-location-dot me-2 mt-2"></i
                      >第五集：背道而馳的歸途</span
                    >
                  </h2>
                  <div class="d-block text-left">
                    <p class="card-text text-white pt-2 pb-2 text-shadow font-1-2em">
                      西元1897年8月31日，歷經二十九天的行程，原住民日本觀光首發團返抵基隆港。當輪船進入港灣內，大夥們興奮地站在甲板上，望著先前才由此出海，卻不算熟悉的環境，無論如何，他們知道接下來就是回家的路了。然而，歷經這趟遠赴日本的旅程，他們多少感受到一種帝國文明的衝擊，由是衍生了許多困惑與思索，漸漸地在每個人的內心中發酵。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 時間軸 結束-->
</template>

<script>
import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

export default {
  name: "Section5",
  props: {},
  setup() {
    const target1 = ref(null);
    const target2 = ref(null);
    const target3 = ref(null);
    const target4 = ref(null);
    const target5 = ref(null);
    const target6 = ref(null);
    const targetIsVisible1 = ref(false);
    const targetIsVisible2 = ref(false);
    const targetIsVisible3 = ref(false);
    const targetIsVisible4 = ref(false);
    const targetIsVisible5 = ref(false);
    const targetIsVisible6 = ref(false);
    // eslint-disable-next-line
    const { stop1 } = useIntersectionObserver(
      target1,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible1.value) {
          targetIsVisible1.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop2 } = useIntersectionObserver(
      target2,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible2.value) {
          targetIsVisible2.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop3 } = useIntersectionObserver(
      target3,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible3.value) {
          targetIsVisible3.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop4 } = useIntersectionObserver(
      target4,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible4.value) {
          targetIsVisible4.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop5 } = useIntersectionObserver(
      target5,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible5.value) {
          targetIsVisible5.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop6 } = useIntersectionObserver(
      target6,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible6.value) {
          targetIsVisible6.value = isIntersecting;
        }
      }
    );
    return {
      target1,
      target2,
      target3,
      target4,
      target5,
      target6,
      targetIsVisible1,
      targetIsVisible2,
      targetIsVisible3,
      targetIsVisible4,
      targetIsVisible5,
      targetIsVisible6,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sbg-2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  background-attachment: fixed;
  background-image: url("/images/home/section_bg_2.jpg");
}
.loading {
  background-image: url("/images/home/loading.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-color: #FFFFFF;
}
</style>
