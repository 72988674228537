<template>
  <!-- 區塊1 開始-->
  <div class="container-fluid position-relative p-0 mt-0" style="z-index:10; background-color: #003e57">
    <div
      class="head-bg-1 d-flex justify-content-center align-items-center animate__animated animate__fadeIn"
    >
      <img
        class="head-bg-3 animate__animated animate__fadeIn"
        src="/images/home/head/bg-3.png"
      />
    </div>
  </div>
  <!-- 區塊1 結束-->
</template>

<script>
export default {
  name: "Section1",
  props: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.head-bg-1 {
  /* background-image: url("/images/home/head/bg-1.png");
  background-position: top;
  background-repeat: repeat; */
  background-color: white;
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 980px;
  background: url("/images/home/head/bg-1.png") repeat 0 0;
  -webkit-animation: bg-scrolling-reverse 5s infinite;
  /* Safari 4+ */
  -moz-animation: bg-scrolling-reverse 5s infinite;
  /* Fx 5+ */
  -o-animation: bg-scrolling-reverse 5s infinite;
  /* Opera 12+ */
  animation: bg-scrolling-reverse 5s infinite;
  /* IE 10+ */
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -o-animation-timing-function: linear;
  animation-timing-function: linear;
}
/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: -250px 125px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: -250px 125px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: -250px 125px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: -250px 125px;
  }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: -250px 125px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: -250px 125px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: -250px 125px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: -250px 125px;
  }
}
.head-bg-2 {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  /* height: 980px; */
  z-index: 2;
  top: 0px;
  left: 1080px;
  animation-delay: 0.5s;
}
.head-bg-3 {
  position: relative;
  width: 1100px;
  height: auto;
  z-index: 3;
  top: -30px;
  animation-delay: 0.5s;
}

@media screen and (max-width: 700px) {
  .head-bg-1 {
    height: 420px;
  }
  .head-bg-2 {
    width: 100%;
    height: auto;
    display: none;
  }
  .head-bg-3 {
    width: 100%;
    top: 20px;
  }
}
</style>
