<template>
  <!-- 簡介 開始 -->
  <div
    class="container-fluid ps-0 pe-0 pt-3 pb-3 pt-lg-4 pb-lg-5 d-block position-relative sbg-7" style="z-index:10"
  >
    <div class="container">
      <div
        class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
      >
        <h2
          class="fw-bold d-flex align-items-center rounded-top pt-2 pb-2"
          ref="target1"
          :class="{ 'animate__animated animate__fadeIn': targetIsVisible1, 'opacity-0': !targetIsVisible1 }"
        >
          <i
            aria-hidden="true"
            class="fa-solid fa-fw fa-book text-primary font-1-2em me-2"
          ></i
          ><span class="text-dark font-1-2em fw-bold"
            >蕃人觀光日誌
            <br class="d-xl-none">
            <small>Taiwan Indigenous Tourism Journals to Japan</small></span
          >
        </h2>
      </div>
      <div class="row g-4 d-flex justify-content-center" id="ar1">
        <div class="col-12">
          <p
            class="lh-lg font-1-2em"
            ref="target2"
            :class="{ 'animate__animated animate__fadeIn': targetIsVisible2, 'opacity-0': !targetIsVisible2 }"
          >
            《蕃人觀光日誌》一書係由臺灣總督府技師藤根吉春編撰，內容詳載1897年8月，總督府第一次安排臺灣原住民赴日參訪的行程內容，包括10位參與原住民的背景資料、29天隨行者的觀察紀錄，以及作者藤根吉春的事後檢討與建議。透過這份參訪報告書，我們不僅看到臺灣原住民在踏上日本國土時，對於眼前異國風情與新奇事物，顯露出的驚訝與不解；同時，在藤根氏的描述下，臺灣原住民與日本統治者間就其族群意識、生活與文化觀點的差異，都清晰地被呈現在他們之間的對話與行為表現裡，這也成為書中最值得關注省思的焦點。
          </p>
          <p
            class="lh-lg font-1-2em"
            ref="target3"
            :class="{ 'animate__animated animate__fadeIn': targetIsVisible3, 'opacity-0': !targetIsVisible3 }"
          >
            Taiwan Indigenous Tourism Journals to Japan was compiled by Fujine
            Yoshiharu, a technician under the employment of the
            Government-General of Taiwan, and provides a detailed account of the
            itinerary for the first visit of Taiwan's indigenous people to Japan
            arranged by the Government-General of Taiwan in August 1897. The
            book includes background information on the 10 indigenous
            participants, observations recorded during their 29-day journey, and
            Fujine Yoshiharu's post-event reflections and recommendations.
            Through this visit report, readers not only gain a sense of the
            amazement and bewilderment of Taiwan's indigenous people as they set
            foot on Japanese soil, encountering foreign customs and novel
            experiences, but also explore the differences in ethnic
            consciousness, lifestyles, and cultural perspectives between
            Taiwan's indigenous people and the Japanese colonial rulers through
            Fujine's descriptions. These differences are vividly portrayed
            through their dialogues and behaviors, making it a significant and
            thought-provoking aspect of the book.
          </p>
        </div>
        <div class="col-12 col-lg-4">
          <figure
            class="figure"
            ref="target4"
            :class="{ 'animate__animated animate__fadeIn': targetIsVisible4, 'opacity-0': !targetIsVisible4}"
          >
            <img
              class="img-fluid rounded rounded-5 img-thumbnail"
              src="/images/home/section2-1.png"
              alt=""
            />
            <!-- <figcaption class="figure-caption">圖片註解1</figcaption> -->
          </figure>
        </div>
        <div
          class="col-12 col-lg-4"
          ref="target5"
          :class="{ 'animate__animated animate__fadeIn': targetIsVisible5, 'opacity-0': !targetIsVisible5 }"
        >
          <figure class="figure">
            <img
              class="img-fluid rounded rounded-5 img-thumbnail"
              src="/images/home/section2-2.png"
              alt=""
            />
            <!-- <figcaption class="figure-caption">圖片註解2</figcaption> -->
          </figure>
        </div>
        <div
          class="col-12 col-lg-4"
          ref="target6"
          :class="{ 'animate__animated animate__fadeIn': targetIsVisible6, 'opacity-0': !targetIsVisible6 }"
        >
          <figure class="figure">
            <img
              class="img-fluid rounded rounded-5 img-thumbnail"
              src="/images/home/section2-3.png"
              alt=""
            />
            <!-- <figcaption class="figure-caption">圖片註解3</figcaption> -->
          </figure>
        </div>
      </div>
    </div>
  </div>
  <!-- 簡介 結束 -->
</template>

<script>
import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

export default {
  name: "Section2",
  data() {
    return {};
  },
  props: {},
  components: {},
  methods: {},
  mounted() {},

  setup() {
    const target1 = ref(null);
    const target2 = ref(null);
    const target3 = ref(null);
    const target4 = ref(null);
    const target5 = ref(null);
    const target6 = ref(null);
    const targetIsVisible1 = ref(false);
    const targetIsVisible2 = ref(false);
    const targetIsVisible3 = ref(false);
    const targetIsVisible4 = ref(false);
    const targetIsVisible5 = ref(false);
    const targetIsVisible6 = ref(false);
    // eslint-disable-next-line
    const { stop1 } = useIntersectionObserver(
      target1,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible1.value) {
          targetIsVisible1.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop2 } = useIntersectionObserver(
      target2,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible2.value) {
          targetIsVisible2.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop3 } = useIntersectionObserver(
      target3,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible3.value) {
          targetIsVisible3.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop4 } = useIntersectionObserver(
      target4,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible4.value) {
          targetIsVisible4.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop5 } = useIntersectionObserver(
      target5,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible5.value) {
          targetIsVisible5.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop6 } = useIntersectionObserver(
      target6,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible6.value) {
          targetIsVisible6.value = isIntersecting;
        }
      }
    );
    return {
      target1,
      target2,
      target3,
      target4,
      target5,
      target6,
      targetIsVisible1,
      targetIsVisible2,
      targetIsVisible3,
      targetIsVisible4,
      targetIsVisible5,
      targetIsVisible6,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sbg-7 {
  background-repeat: repeat;
  background-image: url("/images/home/section_bg_4.png");
}
</style>

