<template>
  <!-- 社群網站 開始 -->
  <div
    class="container-fluid ps-0 pe-0 pt-2 pb-2 pt-lg-4 pb-lg-5 d-block sbg-7 position-relative"
    style="z-index: 10"
  >
    <div class="container rounded p-4">
      <!-- <div
        class="d-flex flex-row justify-content-between pb-0 section-title mb-2 mb-lg-4"
      >
        <h2 class="fw-bold d-flex align-items-center pt-2 pb-2">
          <i
            aria-hidden="true"
            class="fa-solid fa-fw fa-share-nodes text-primary me-2"
          ></i
          ><span class="text-dark font-1-2em fw-bold">東南社群</span>
        </h2>
      </div> -->
      <div
        class="row g-4 d-flex justify-content-center"
        ref="target1"
        :class="{
          'animate__animated animate__fadeInDown': targetIsVisible1,
          'opacity-0': !targetIsVisible1,
        }"
      >
        <div class="col-12 col-lg-5">
          <h3 class="w-100 fs-4 mb-0 p-3 border-bottom">
            <a
              href="https://www.facebook.com/1897Journey/"
              target="_blank"
              class="text-decoration-none text-black"
              title="前往Facebook粉絲專頁(另開視窗)"
            >
              <i
                aria-hidden="true"
                class="fa-brands fa-facebook me-1 text-primary"
              ></i
              >Facebook粉絲專頁</a
            >
          </h3>

          <iframe
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2F1897Journey&tabs=timeline&width=500&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            width="100%"
            height="450"
            style="border: none; overflow: hidden"
            scrolling="no"
            frameborder="0"
            allowfullscreen="true"
            title="Facebook粉絲專頁"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          >
            <a
              href="https://www.facebook.com/TNUFB/?locale=zh_TW"
              target="_blank"
              title="前往Facebook粉絲專頁(另開視窗)"
              >Facebook粉絲專頁</a
            >
          </iframe>
        </div>
      </div>
    </div>
  </div>
  <!-- 社群網站 結束 -->
</template>

<script>
import { ref } from "vue";
import { useIntersectionObserver } from "@vueuse/core";

export default {
  name: "Section3",
  props: {},
  setup() {
    const target1 = ref(null);
    const target2 = ref(null);
    const target3 = ref(null);
    const target4 = ref(null);
    const target5 = ref(null);
    const target6 = ref(null);
    const targetIsVisible1 = ref(false);
    const targetIsVisible2 = ref(false);
    const targetIsVisible3 = ref(false);
    const targetIsVisible4 = ref(false);
    const targetIsVisible5 = ref(false);
    const targetIsVisible6 = ref(false);
    // eslint-disable-next-line
    const { stop1 } = useIntersectionObserver(
      target1,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible1.value) {
          targetIsVisible1.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop2 } = useIntersectionObserver(
      target2,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible2.value) {
          targetIsVisible2.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop3 } = useIntersectionObserver(
      target3,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible3.value) {
          targetIsVisible3.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop4 } = useIntersectionObserver(
      target4,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible4.value) {
          targetIsVisible4.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop5 } = useIntersectionObserver(
      target5,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible5.value) {
          targetIsVisible5.value = isIntersecting;
        }
      }
    );
    // eslint-disable-next-line
    const { stop6 } = useIntersectionObserver(
      target6,
      // eslint-disable-next-line
      ([{ isIntersecting }], observerElement) => {
        if (!targetIsVisible6.value) {
          targetIsVisible6.value = isIntersecting;
        }
      }
    );
    return {
      target1,
      target2,
      target3,
      target4,
      target5,
      target6,
      targetIsVisible1,
      targetIsVisible2,
      targetIsVisible3,
      targetIsVisible4,
      targetIsVisible5,
      targetIsVisible6,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sbg-7 {
  background-repeat: repeat;
  background-image: url("/images/home/section_bg_4.png");
}
</style>
