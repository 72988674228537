<template>
  <Head/>
  <Section1/>
  <a href="#C" title="中央內容區塊，為本頁主要內容區" id="C" accesskey="C" name="C" class="visually-hidden-focusable">:::</a>
  <Section2/>
  <Section5/>
  <Section3/>
  <Foot/>
  <div id="video-mask"></div>
  <video class="video1" autoplay muted loop id="bg-video">
    <source src="/files/home/bg-video.mp4?3" type="video/mp4" />
  </video>
</template>

<script>
import Head from '@/components/layout/Head.vue'
import Foot from '@/components/layout/Foot.vue'
import Section1 from '@/components/Section1.vue'
import Section2 from '@/components/Section2.vue'
import Section3 from '@/components/Section3.vue'
// import Section4 from '@/components/Section4.vue'
import Section5 from '@/components/Section5.vue'
// import Section6 from '@/components/Section6.vue'

export default {
  name: "Index",
  data() {
    return {
      
    };
  },
  components: {
    Head,
    Foot,
    Section1,
    Section2,
    Section3,
    // Section4,
    Section5,
    // Section6,
  },
  watch: {
    $route() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }
};
</script>
<style>
#bg-video {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}
#video-mask {
  background-color: rgba(0, 0, 0, 0.3);
  /* background: white; */
  background-image: radial-gradient(rgba(0, 0, 0, 0.2) 1px, transparent 0);
  background-size: 3px 3px;
  background-position: -19px -19px;
  position: fixed;
  right: 0;
  top: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  /* @include media-breakpoint-down(sm) {
    background-image: url('../../assets//images/bg.jpg');
    background-repeat: no-repeat;
    background-position: top center;
  } */
}
</style>
